<template>
	<div>
		<v-row class="mt-1 ml-2 mb-3">
			<v-col cols="9">
				<h2 class="primary--text">Master Payroll</h2>
			</v-col>

			<v-col cols="3" class="text-right">
				<!-- export payroll -->
				<v-menu transition="scale-transition" origin="center center" offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" v-bind="attrs" v-on="on" @click="exportPayroll">
							Export Payroll
							<v-icon class="pl-1" size="24"> mdi-export </v-icon>
						</v-btn>
					</template>
				</v-menu>
			</v-col>
		</v-row>

		<!-- search -->
		<v-form>
			<v-row>
				<v-col cols="12" sm="3" md="3">
					<v-text-field @keyup.enter="handleFilter" solo prepend-inner-icon="mdi-magnify" label="Name" dense
						clearable flat @click:clear="resetData" color="primary" background-color="white lighten-4"
						v-model="filterForm.name"></v-text-field>
				</v-col>
				<v-col cols="12" sm="3" md="3">
					<v-select solo dense flat label="Department" :items="departments" item-text="name"
						item-value="abbreviation" v-model="filterForm.department"></v-select>
				</v-col>

				<!-- filter payroll -->
				<v-col cols="12" sm="3" md="3">
					<v-menu ref="menu" v-model="menu" :close-on-content-click="false"
						:return-value.sync="filterForm.date" transition="scale-transition" offset-y max-width="290px"
						min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field dense solo flat background-color="white" placeholder="yyyy-mm"
								v-model="filterForm.date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
								v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="filterForm.date" type="month" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="menu = false">
								Cancel
							</v-btn>
							<v-btn text color="primary" @click="$refs.menu.save(filterForm.date)">
								OK
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>

				<v-col cols="12" md="3" sm="3" class="d-flex justify-content-between">
					<v-btn color="primary" @click.prevent="handleFilter">filter</v-btn>

					<v-btn color="#eff" class="primary--text" @click.prevent="resetData">Clear</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- Spinner -->
		<div v-if="isLoading">
			<spinner></spinner>
		</div>

		<v-data-table fixed-header id="payroll-table" :headers="newHeaders" :items="payrollReport" disable-sort
			class="table-rounded" :items-per-page="itemsPerPage" hide-default-footer>
			<template v-slot:[`item.no`]="{ index }">
				{{ index + 1 + pageNumbering }}
			</template>
			<template v-slot:[`item.displayName`]="{ item }">
				{{ item?.employee?.displayName }}
			</template>
			<template v-slot:[`item.employeeId`]="{ item }">
				{{ item?.employee?.employeeId }}
			</template>
			<template v-slot:[`item.employeePosition`]="{ item }">
				{{ item?.employee?.employeePosition }}
			</template>
			<template v-slot:[`item.basicSalary`]="{ item }">
				{{ formatFigure(item?.employee?.basicSalary) }}
			</template>
			<template v-slot:[`item.totalAllowance`]="{ item }">
				{{ formatFigure(item?.totalAllowance) }}
			</template>
			<template v-slot:[`item.initialBonus`]="{ item }">
				{{ formatFigure(item?.initialBonus) }}
			</template>
			<template v-slot:[`item.bonusTotal`]="{ item }">
				{{ formatFigure(item?.bonusTotal) }}
			</template>
			<template v-slot:[`item.excessBonus`]="{ item }">
				{{ formatFigure(item?.excessBonus) }}
			</template>
			<template v-slot:[`item.backPay`]="{ item }">
				{{ formatFigure(item?.employee.backPay) }}
			</template>

			<template v-slot:[`item.initialGross`]="{ item }">
				{{ formatFigure(item?.initialGross) }}
			</template>
			<template v-slot:[`item.employeeSSF`]="{ item }">
				{{ formatFigure(item?.employeeSSF) }}
			</template>
			<template v-slot:[`item.employeeTierThreePre`]="{ item }">
				{{ formatFigure(item?.employeeTierThreePre) }}
			</template>
			<template v-slot:[`item.taxRelief`]="{ item }">
				{{ formatFigure(item?.taxRelief) }}
			</template>
			<template v-slot:[`item.totalRelief`]="{ item }">
				{{ formatFigure(item?.totalRelief) }}
			</template>
			<template v-slot:[`item.taxableIncome`]="{ item }">
				{{ formatFigure(item?.taxableIncome) }}
			</template>

			<template v-slot:[`item.totalTaxOnBonus`]="{ item }">
				{{ formatFigure(item?.totalTaxOnBonus) }}
			</template>
			<template v-slot:[`item.paye`]="{ item }">
				{{ formatFigure(item?.paye) }}
			</template>
			<template v-slot:[`item.studentLoanTrustFund`]="{ item }">
				{{ formatFigure(item?.studentLoanTrustFund) }}
			</template>
			<template v-slot:[`item.loanDeduction`]="{ item }">
				{{ formatFigure(item?.loanDeduction) }}
			</template>
			<template v-slot:[`item.totalDeduction`]="{ item }">
				{{ formatFigure(item?.totalDeduction) }}
			</template>
			<template v-slot:[`item.netSalary`]="{ item }">
				{{ formatFigure(item?.netSalary) }}
			</template>
			<template v-slot:[`item.employerSSF`]="{ item }">
				{{ formatFigure(item?.employerSSF) }}
			</template>
			<template v-slot:[`item.employerTierThreePre`]="{ item }">
				{{ formatFigure(item?.employerTierThreePre) }}
			</template>
			<template v-slot:[`item.employeeCostToCompany`]="{ item }">
				{{ formatFigure(item?.employeeCostToCompany) }}
			</template>

			<template slot="body.append">
				<tr class="primary--text footer">
					<th class="subtitle-2" id="totals">TOTALS</th>
					<th class="subtitle-2" id="th1"></th>
					<th class="subtitle-2" id="th2"></th>
					<th class="subtitle-2" id="th3"></th>
					<th class="subtitle-2 table-bordered" id="basicSalary">
						{{ formatFigure(masterPayrollTotals?.basicSalary) }}
					</th>
					<th class="subtitle-2 table-bordered" id="allowance">
						{{ formatFigure(masterPayrollTotals?.allowance) }}
					</th>
					<th class="subtitle-2 table-bordered" id="initialBonus">
						{{ formatFigure(masterPayrollTotals?.initialBonus) }}
					</th>
					<th class="subtitle-2 table-bordered" id="bonus">
						{{ formatFigure(masterPayrollTotals?.bonus) }}
					</th>
					<th class="subtitle-2 table-bordered" id="excessBonus">
						{{ formatFigure(masterPayrollTotals?.excessBonus) }}
					</th>
					<th class="subtitle-2 table-bordered" id="backPay" v-if="this.masterPayrollTotals?.backPay > 0">
						{{ formatFigure(masterPayrollTotals?.backPay) }}
					</th>
					<th class="subtitle-2 table-bordered" id="initialGross">
						{{ formatFigure(masterPayrollTotals?.initialGross) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employeeSSF">
						{{ formatFigure(masterPayrollTotals?.employeeSSF) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employeeTier3">
						{{ formatFigure(masterPayrollTotals?.employeeTierThreePre) }}
					</th>
					<th class="subtitle-2 table-bordered" id="taxRelief">
						{{ formatFigure(masterPayrollTotals?.taxRelief) }}
					</th>
					<th class="subtitle-2 table-bordered" id="totalRelief">
						{{ formatFigure(masterPayrollTotals?.totalRelief) }}
					</th>
					<th class="subtitle-2 table-bordered" id="taxableIncome">
						{{ formatFigure(masterPayrollTotals?.taxableIncome) }}
					</th>
					<th class="subtitle-2 table-bordered" id="bonusTax">
						{{ formatFigure(masterPayrollTotals?.bonusTax) }}
					</th>
					<th class="subtitle-2 table-bordered" id="paye">
						{{ formatFigure(masterPayrollTotals?.paye) }}
					</th>
					<th class="subtitle-2 table-bordered" id="studentLoanTrustFund">
						{{ formatFigure(masterPayrollTotals?.studentLoanTrustFund) }}
					</th>
					<th class="subtitle-2 table-bordered" id="totalLoanDeduction">
						{{ formatFigure(masterPayrollTotals?.totalLoanDeduction) }}
					</th>
					<th class="subtitle-2 table-bordered" id="deductions">
						{{ formatFigure(masterPayrollTotals?.deductions) }}
					</th>
					<th class="subtitle-2 table-bordered" id="netSalary">
						{{ formatFigure(masterPayrollTotals?.netSalary) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employerSSF">
						{{ formatFigure(masterPayrollTotals?.employerSSF) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employerTier3">
						{{ formatFigure(masterPayrollTotals?.employerTierThreePre) }}
					</th>
					<th class="subtitle-2 table-bordered" id="employeeCostToCompany">
						{{ formatFigure(masterPayrollTotals?.employeeCostToCompany) }}
					</th>
				</tr>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE ! </template>
		</v-data-table>

		<div class="text-center pt-2">
			<v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
			<template> </template>
		</div>
	</div>
</template>

<script>
import { EXPORT_PAYROLL_BY_DATE } from "../../services/graphql/queries/all_queries";
import { Parser } from "json2csv";
import moment from "moment";
import helpers from "../../services/helpers";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import methMixin from "../../services/mixins/paginateMethodMixin";
import { createHeaders } from "../../services/functions";
export default {
	mixins: [helpers, generalPageMixin, methMixin],
	data: () => ({
		menu: false,
		today: new Date(),
		isLoading: false,
		itemsPerPage: 20,
		dialog: false,
		dialogDelete: false,
		query: "",
		// masterPayrollTotals: null,
		departments: [
			{ name: "All Departments", abbreviation: "" },
			{ name: "Service Center", abbreviation: "SC" },
			{ name: "Training Center", abbreviation: "TRN" },
			{ name: "Operations", abbreviation: "OP" },
			{ name: "Management", abbreviation: "MG" },
		],
		filterForm: new Form({
			name: "",
			department: "",
			date: "",
		}),
		storePath: "report",
		sourcePath: "report",
		actionType: "fetchPayrollReport",
		headers: createHeaders("payrollReport"),
		header: [
			"Name of Employee",
			"Employee ID",
			"Job Title",
			"Basic Salary",
			"Total Allowance",
			"Total Bonus",
			"Gross Salary",
			"Employee SSF",
			"Tax Relief",
			"Taxable Income",
			"Bonus Tax",
			"Paye",
			"Loan Deduction",
			"Total Deduction",
			"Net Salary",
		],
	}),
	computed: {
		payrollReport() {
			return this.$store.getters["report/allPayrollReport"];
		},
		masterPayrollTotals() {
			return this.$store.getters["report/getMasterPayrollTotals"];
		},
		pageNumbering() {
			if (this.page === 0) {
				return 0;
			} else {
				return this.page * 20 - 20;
			}
		},
		currentYear() {
			return new Date().getFullYear();
		},

		currentMonth() {
			return ("0" + (new Date().getMonth() + 1)).slice(-2);
		},
		data() {
			let today = new Date();
			let date =
				today.getFullYear() +
				"-" +
				("0" + (today.getMonth() + 1)).slice(-2);
			return {
				yearMonth: date,
			};
		},

		// hide the back pay column when there is no back pay
		newHeaders() {
			if (this.masterPayrollTotals?.backPay > 0) {
				return this.headers;
			}
			return this.headers.filter(
				(header) => header.text !== "BACK PAY (GH₵)"
			);
		},
	},
	async created() {
		this.isLoading = true;
		this.filterForm.date = this.data.yearMonth;
		await this.initialize();
		this.isLoading = false;
	},

	methods: {
		async initialize() {
			await this.$store.dispatch("report/fetchPayrollReport", {
				data: this.filterForm.date ?? this.data.yearMonth,
				page: this.page || 1,
			});
			await this.getMasterPayrollTotal();
		},

		overallBonus(array, key) {
			let overallBonus = 0;
			array.map((e) => {
				overallBonus += e[key];
			});
			return overallBonus;
		},

		async getMasterPayrollTotal() {
			this.isLoading = true;
			await this.$store.dispatch("report/fetchMasterPayrollTotals", {
				data: {
					yearMonth: this.filterForm.date,
					startWith: this.filterForm.department,
				},
			});
			this.isLoading = false;
		},

		async resetData() {
			this.isLoading = true;
			this.filterForm.reset();
			this.filterForm.date = this.data.yearMonth;
			await this.initialize();
			this.isLoading = false;
		},

		async handleFilter() {
			this.isLoading = true;
			await this.$store.dispatch("report/filterPayrollReport", {
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: this.filterForm.date,
				page: this.page,
			});
			await this.getMasterPayrollTotal();
			this.isLoading = false;
		},

		async exportPayroll() {
			let page = 0;
			this.isLoading = true;
			const data = {
				name: this.filterForm.name || "",
				startWith: this.filterForm.department,
				yearMonth: `${this.filterForm.date}`,
			};
			const date = moment(data.yearMonth).format("MMM YYYY");

			this.$apollo
				.query({
					query: EXPORT_PAYROLL_BY_DATE,
					variables: {
						page: page,
						size: 20,
						searchName: data.name,
						startWith: data.startWith,
						yearMonth: data.yearMonth,
						responseType: "blob",
						headers: { Accept: "multipart/form-data" },
					},
				})
				.then((response) => {
					const fileData = response.data.exportPayrollByDate.data;
					const cleanFileData = fileData.map((item, index) => ({
						"NO.": index + 1,
						"EMPLOYEE NAME": item?.employee.name,
						"EMPLOYEE ID": item?.employee.employeeId,
						"JOB TITLE": item?.employee.employeePosition,
						"BASIC SALARY (GH₵)": item?.employee.basicSalary,
						"TOTAL ALLOWANCE (GH₵)": item?.totalAllowance,
						"TOTAL BONUS (GH₵)": item?.initialBonus,
						"ACTUAL BONUS (GH₵)": item?.bonusTotal,
						"EXCESS BONUS (GH₵)": item?.excessBonus,
						"BACK PAY (GH₵)": item?.backPay,
						"GROSS SALARY (GH₵)": item?.grossSalary,
						"EMPLOYEE SSF (GH₵)": item?.employeeSSF,
						"EMPLOYEE TIER 3 (GH₵)": item?.employeeTierThreePre,
						"TAX RELIEF (GH₵)": item?.taxRelief,
						"TOTAL RELIEF (GH₵)": item?.totalRelief,
						"TAXABLE INCOME (GH₵)": item?.taxableIncome,
						"BONUS TAX (GH₵)": item?.totalTaxOnBonus,
						"PAYE (GH₵)": item?.paye,
						"STUDENT LOAN (GH₵)": item?.studentLoanTrustFund,
						"LOAN DEDUCTION (GH₵)": item?.loanDeduction,
						"TOTAL DEDUCTION (GH₵)": item?.totalDeduction,
						"NET SALARY (GH₵)": item?.netSalary,
						"EMPLOYER SSF (GH₵):": item?.employerSSF,
						"EMPLOYER TIER 3 (GH₵):": item?.employerTierThreePre,
						"EMPLOYEE COST TO COMPANY (GH₵)": item?.employeeCostToCompany,

					}))
					const title = `EMPLOYEES PAYROLL FOR - ${date}.csv`;
					const parser = new Parser();
					const csv = parser.parse(cleanFileData);
					const blob = new Blob([csv], { type: "text/xls" });
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = title;
					link.click();
					link.remove();
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error)
				});
		},
	},
};
</script>

<style>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}

#payroll-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2),
#payroll-table>.v-data-table__wrapper>table>thead>tr>th:nth-child(2) {
	position: sticky !important;
	position: -webkit-sticky !important;
	left: 0;
	z-index: 98;
	background: white;
}

#payroll-table>.v-data-table__wrapper>table>thead>tr>th:nth-child(2) {
	z-index: 99 !important;
	background: #f5f6fa;
}
</style>
